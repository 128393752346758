import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import ContactForm from "../components/ContactForm"

import SyncLogo from "../images/sync-bionics-footer-logo.svg"


const Contact = ({ data }) => (
  <Layout>
    <SEO title="Contact Us" />
    <Container fluid >
      <Row className="form-row">
        <Col className="form-column border-logo" md={6} sm={12} style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'

        }}>
          <div className="form-column">
        <img
                src={SyncLogo}
                style={{
                  width: "256px",
                  borderBottom: "1px solid #707070",
                  paddingBottom: "10px",
                }}
              />
              <div style={{}}>
                <p
                  style={{
                    fontSize: "1.2rem",
                  }}
                >
                  Sync Bionics
                  <br />
                  2056 S 1100 E<br />
                  Salt Lake City, UT 84106
                </p>
              </div>
              <div style={{
          alignSelf: 'flex-start'
        }} className="btn btn-success btn-sync button-txt"
        >EMPLOYMENT</div>
              </div>
        </Col>
        <Col md={6} sm={12}>
          <div className="form-column">
          <h1 style={{
            color: 'black',
            fontSize: '2rem',
            fontWeight: 'bold'
          }}>Contact Us</h1>
          <ContactForm />
          </div>
        </Col>
      </Row>
      
    </Container>
  </Layout>
)


export default Contact
