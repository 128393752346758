import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Spinner, Form, Button } from "react-bootstrap"
import { Formik, ErrorMessage } from "formik"
import * as Yup from "yup"
// import "./ContactForm.css"

// RegEx for phone number validation
//const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

// Schema for yup
const validationSchema = Yup.object().shape({
  yourName: Yup.string()
    .min(2, "*Names must have at least 2 characters")
    .max(100, "*Names can't be longer than 100 characters")
    .required("*Name is required"),
  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  subject: Yup.string()
    .min(2, "*Names must have at least 2 characters")
    .max(100, "*Names can't be longer than 100 characters")
    .required("*Subject is required"),
  yourMessage: Yup.string()
    .min(2, "*Names must have at least 2 characters")
    .max(100, "*Names can't be longer than 100 characters")
    .required("*A message is required"),
})

const ContactForm = () => {
  return (
    <div>
      <Formik
        initialValues={{
          yourName: "",
          email: "",
          subject: "",
          yourMessage: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          fetch(
            "https://cors-anywhere.herokuapp.com/https://gqr5v416dd.execute-api.us-west-2.amazonaws.com/production/contact-us",
            {
              headers: { "Content-Type": "application/json; charset=utf-8" },
              method: "POST",
              body: JSON.stringify(values),
            }
          ).then(response => {
            if (response.status === 200) {
              actions.setStatus({
                success: "Message Sent",
              })
            } else {
              actions.setStatus({
                fail: "Network Error",
              })
            }
          })

          setTimeout(() => {
            console.log(values)
            actions.resetForm()
            actions.setSubmitting(false)
          }, 400)
        }}
      >
        {({
          status,
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            onSubmit={handleSubmit}
            // success={!!status && !!status.success}
            // fail={!!status && !!status.fail}
          >
            {console.log(values)}
            <Form.Group>
              <Form.Label>
                Name{" "}
                {touched.yourName && errors.yourName ? (
                  <div className="error-message">{errors.yourName}</div>
                ) : null}
              </Form.Label>
              <Form.Control
                type="text"
                name="yourName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.yourName}
                className={touched.yourName && errors.yourName ? "error" : null}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Email{" "}
                {touched.email && errors.email ? (
                  <div className="error-message">{errors.email}</div>
                ) : null}
              </Form.Label>
              <Form.Control
                type="text"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className={touched.email && errors.email ? "error" : null}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Subject{" "}
                {touched.subject && errors.subject ? (
                  <div className="error-message">{errors.subject}</div>
                ) : null}
              </Form.Label>
              <Form.Control
                type="text"
                name="subject"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.subject}
                className={touched.subject && errors.subject ? "error" : null}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Your Message{" "}
                {touched.yourMessage && errors.yourMessage ? (
                  <div className="error-message">{errors.yourMessage}</div>
                ) : null}
              </Form.Label>
              <Form.Control
                as="textarea"
                name="yourMessage"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.yourMessage}
                className={
                  touched.yourMessage && errors.yourMessage ? "error" : null
                }
              />
            </Form.Group>
            <Button
              variant="success"
              className="btn btn-success btn-sync button-txt"
              type="submit"
              disabled={isSubmitting}
            >
              
              { status && status.success ? "Message Sent!"
              : isSubmitting ? <div><Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{
                  marginRight: '10px'
              }}
            /> "Submitting"</div>
              : "SUBMIT"
            }
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ContactForm
